const labelMirror = {
  // DB label : UI label
  OrderType: "Order type",
  TotalPrice: "Total",
  totalafterDiscount: "After discount",
  waiter_name: "Waiter name",
  ItemName: "Name",
  ItemPrice: "Item price",
  TotalItemPrice: "Total item price",
  CategoryName: "Category",
  Qty: "Quatity",
  totalQty: "Quatity",
  TotalSales: "Total",
  PayDate: "Date",
  Description: "Spending",
  EmpName: "Employee",
  OpeningBalance: "Previous credit",
  Balance: "Post credit",
  LogDate: "Date",
  Owned: "Owed to him",
  PaidUp: "Bill description",
  DayDate: "Date",
  BankDeposit: "Deposit",
  MainSafeBuyBill: "Purchase invoices",
  MainSafeSpendings: "Expanses safe",
  BankTake: "Withdrawal",
  Spendings: "Spending",
  SupplierName: "Supplier",
  BillNo: "Bill no",
  SafeName: "Name",
  PK_Shift_ID: "ID",
  StartTime: "Start Date",
  startTimeCopy: "Start Time",
  Endtime: "End Date",
  endTimeCopy: "End Time",
  Total_Orders: "Orders",
  Net_Orders_Value: "Orders Value",
  Net_Cash: "Net Cash",
  Total_Visa: "Cards",
  Total_Companies: "Total Co.",
  CompOrderValue: "Company Order Value",
  CompanyName: "Company Name",
}

const convertToUiLabel = label => {
  return labelMirror[label] || label
}

export { convertToUiLabel }
