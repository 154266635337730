import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  UncontrolledTooltip,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"

import { useSelector, useDispatch } from "react-redux"
import { getShiftReport } from "store/actions"
import DateFrom from "../shared/DateFrom"
import SelectEmployee from "../shared/SelectEmployee"
import { convertParametersToString } from "pages/Reports/helpers"

const FilterByEmployee = props => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(null)
  const [currentEmpId, setcurrentEmpId] = useState("")

  const reports = useSelector(state => state.Reports)
  const { getShiftReport: shiftReportData, loadingShiftReport: loading } =
    reports

  useEffect(() => {
    let params = {}
    if (!date || !currentEmpId || (currentEmpId && currentEmpId === "Select"))
      return
    if (date) params = date
    if (currentEmpId && currentEmpId !== "Select") {
      params = { ...params, empID: currentEmpId }
    }
    const paramters = convertParametersToString(params)
    dispatch(getShiftReport(paramters))
  }, [currentEmpId, date])

  const getDateFromHandler = date => {
    setDate(date)
  }

  const onChangeEmpIdHandler = pkEmpId => {
    setcurrentEmpId(pkEmpId)
  }

  const columns = useMemo(
    () => [
      {
        Header: props.t("No"),
        accessor: "PK_OrderMaster_ID",
        filterable: true,
      },
      {
        Header: props.t("Order Type"),
        accessor: "OrderType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Total"),
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("After Discount"),
        accessor: "totalafterDiscount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("View"),
        Cell: cellProps => {
          const { PK_OrderMaster_ID } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() =>
                    props.toggleViewModalHandler(PK_OrderMaster_ID)
                  }
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {props.t("View")}
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    [shiftReportData, props.t]
  )

  return (
    <Card>
      <CardBody className="border-bottom ml-2">
        <Row className="row-cols-auto gap-4 align-items-center">
          <DateFrom getDateFrom={getDateFromHandler} />
          <SelectEmployee onChangeEmpId={onChangeEmpIdHandler} />
        </Row>
      </CardBody>
      <CardBody>
        {loading ? (
          <div className="text-center py-5">
            <Spinner className="ms-2" color="primary" />
          </div>
        ) : shiftReportData && shiftReportData.Table1 ? (
          <TableContainer
            columns={columns}
            data={
              shiftReportData && shiftReportData.Table1
                ? shiftReportData.Table1
                : []
            }
            isGlobalFilter={false}
            customPageSize={5}
            allListSize={
              shiftReportData && shiftReportData.Table1
                ? shiftReportData.Table1.length
                : 0
            }
          />
        ) : (
          <div style={{ height: "200px" }}></div>
        )}
      </CardBody>
    </Card>
  )
}

FilterByEmployee.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilterByEmployee))
