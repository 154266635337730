// import PropTypes from "prop-types";
// import React, { useState, useEffect } from "react";
// import { Row, Col, Collapse } from "reactstrap";
// import { Link } from "react-router-dom";
// import withRouter from "components/Common/withRouter";
// import classname from "classnames";

// //i18n
// import { withTranslation } from "react-i18next";

// import { connect } from "react-redux";

// const Navbar = props => {
//   const [dashboard, setdashboard] = useState(false);
//   const [ui, setui] = useState(false);
//   const [app, setapp] = useState(false);
//   const [email, setemail] = useState(false);
//   const [ecommerce, setecommerce] = useState(false);
//   const [crypto, setcrypto] = useState(false);
//   const [project, setproject] = useState(false);
//   const [task, settask] = useState(false);
//   const [contact, setcontact] = useState(false);
//   const [blog, setBlog] = useState(false);
//   const [job, setJob] = useState(false);
//   const [candidate, setCandidate] = useState(false);
//   const [component, setcomponent] = useState(false);
//   const [form, setform] = useState(false);
//   const [table, settable] = useState(false);
//   const [chart, setchart] = useState(false);
//   const [icon, seticon] = useState(false);
//   const [map, setmap] = useState(false);
//   const [extra, setextra] = useState(false);
//   const [invoice, setinvoice] = useState(false);
//   const [auth, setauth] = useState(false);
//   const [utility, setutility] = useState(false);

//   useEffect(() => {
//     var matchingMenuItem = null;
//     var ul = document.getElementById("navigation");
//     var items = ul.getElementsByTagName("a");
//     removeActivation(items);
//     for (var i = 0; i < items.length; ++i) {
//       if (window.location.pathname === items[i].pathname) {
//         matchingMenuItem = items[i];
//         break;
//       }
//     }
//     if (matchingMenuItem) {
//       activateParentDropdown(matchingMenuItem);
//     }
//   });

//   const removeActivation = items => {
//     for (var i = 0; i < items.length; ++i) {
//       var item = items[i];
//       const parent = items[i].parentElement;
//       if (item && item.classList.contains("active")) {
//         item.classList.remove("active");
//       }
//       if (parent) {
//         if (parent.classList.contains("active")) {
//           parent.classList.remove("active");
//         }
//       }
//     }
//   };

//   function activateParentDropdown(item) {
//     item.classList.add("active");
//     const parent = item.parentElement;
//     if (parent) {
//       parent.classList.add("active"); // li
//       const parent2 = parent.parentElement;
//       parent2.classList.add("active"); // li
//       const parent3 = parent2.parentElement;
//       if (parent3) {
//         parent3.classList.add("active"); // li
//         const parent4 = parent3.parentElement;
//         if (parent4) {
//           parent4.classList.add("active"); // li
//           const parent5 = parent4.parentElement;
//           if (parent5) {
//             parent5.classList.add("active"); // li
//             const parent6 = parent5.parentElement;
//             if (parent6) {
//               parent6.classList.add("active"); // li
//             }
//           }
//         }
//       }
//     }
//     return false;
//   }
//   console.log(props.leftMenu);
//   return (
//     <React.Fragment>
//       <div className="topnav">
//         <div className="container-fluid">
//           <nav
//             className="navbar navbar-light navbar-expand-lg topnav-menu"
//             id="navigation"
//           >
//             <Collapse
//               isOpen={props.leftMenu}
//               className="navbar-collapse"
//               id="topnav-menu-content"
//             >
//               <ul className="navbar-nav">
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/products"
//                   >
//                     <i className="bx bx-customize mx-2"></i>
//                     {props.t("Products")} {props.menuOpen}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/purchase"
//                   >
//                     <i className="mdi mdi-cart-arrow-right mx-2"></i>
//                     {props.t("Purchase")} {props.menuOpen}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/expenses"
//                   >
//                     <i className="mdi mdi-cart-arrow-right mx-2"></i>
//                     {props.t("Expenses")} {props.menuOpen}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/safe-transfer"
//                   >
//                     <i className="mdi mdi-cart-arrow-right mx-2"></i>
//                     {props.t("SafeTransfer")} {props.menuOpen}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/end-shift"
//                   >
//                     <i className="mdi mdi-cart-arrow-right mx-2"></i>
//                     {props.t("EndShift")}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>
//                 <li className="nav-item dropdown">
//                   <Link
//                     className="nav-link dropdown-toggle arrow-none"
//                     to="/end-shift-report"
//                   >
//                     <i className="mdi mdi-cart-arrow-right mx-2"></i>
//                     {props.t("EndShiftReport")}
//                     {/* <div className="arrow-down"></div> */}
//                   </Link>
//                 </li>

//                 <li className="nav-item dropdown">
//                   <Link
//                     to="/#"
//                     className="nav-link dropdown-toggle arrow-none"
//                     onClick={e => {
//                       e.preventDefault();
//                       setcomponent(!component);
//                     }}
//                   >
//                     <i className="bx bx-collection mx-2"></i>
//                     {props.t("Reports")} <div className="arrow-down"></div>
//                   </Link>
//                   <div
//                     className={classname("dropdown-menu", { show: component })}
//                   >
//                     <div>
//                       <Link
//                         to="/reports/stock-balance"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Stock Balance")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/safe-balance"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Safe Balance")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/supplier-payment"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Supplier Payment")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/net-profit"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Net Profit")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/monthly-report"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Monthly Report")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/daily-report"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Daily Report")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/supplier-account"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Supplier Account")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/expense-report"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Expense Report")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/items-sales-report"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Items Sales Report")}
//                       </Link>
//                     </div>
//                     <div>
//                       <Link
//                         to="/reports/shift-report"
//                         className="dropdown-item dropdown-toggle arrow-none"
//                       >
//                         {props.t("Shift Report")}
//                       </Link>
//                     </div>
//                   </div>
//                 </li>
//               </ul>
//             </Collapse>
//           </nav>
//         </div>
//       </div>
//       {/* overlay  */}
//       {component || props.leftMenu &&
//         <div className="rightbar-overlay z-1" onClick={() => { setcomponent(!component); }}></div>
//       }
//     </React.Fragment>
//   );
// };

// Navbar.propTypes = {
//   leftMenu: PropTypes.any,
//   location: PropTypes.any,
//   menuOpen: PropTypes.any,
//   t: PropTypes.any,
// };

// const mapStatetoProps = state => {
//   const { leftMenu } = state.Layout;
//   return { leftMenu };
// };

// export default withRouter(
//   connect(mapStatetoProps, {})(withTranslation()(Navbar))
// );

import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import classes from "./index.module.scss";
import { toggleLeftmenu } from "../../store/actions";

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [job, setJob] = useState(false);
  const [candidate, setCandidate] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    removeActivation(items);
    for (var i = 0; i < items.length; ++i) {
      if (window.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;
      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active");
        }
      }
    }
  };

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  const closeLeftmenu = useCallback(() => {
    props.toggleLeftmenu(false);
  }, []);

  const closeReportModel = useCallback(() => {
    closeLeftmenu();
    setcomponent(false);
  }, []);

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/products"
                  >
                    <i className="bx bxs-cart mx-2"></i>
                    {props.t("Products")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/purchase"
                  >
                    <i className="bx bxs-purchase-tag-alt mx-2"></i>
                    {props.t("Purchase")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/expenses"
                  >
                    <i className="bx bx-dollar mx-2"></i>
                    {props.t("Expenses")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/safe-transfer"
                  >
                    <i className="bx bx-transfer-alt mx-2"></i>
                    {props.t("SafeTransfer")} {props.menuOpen}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/end-shift"
                  >
                    <i className="mdi mdi-calendar mx-2"></i>
                    {props.t("End Shift")}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={closeLeftmenu}
                    to="/end-shift-report"
                  >
                    <i className="mdi mdi-apple-keyboard-shift mx-2"></i>
                    {props.t("End Shift Report")}
                    {/* <div className="arrow-down"></div> */}
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      setcomponent(!component);
                    }}
                  >
                    <i className="bx bx-collection mx-2"></i>
                    {props.t("Reports")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/stock-balance"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Stock Balance")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/safe-balance"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Safe Balance")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/supplier-payment"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Supplier Payment")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/net-profit"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Net Profit")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/monthly-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Monthly Report")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/daily-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Daily Report")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/supplier-account"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Supplier Account")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/expense-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Expense Report")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/items-sales-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Items Sales Reports")}
                      </Link>
                    </div>
                    <div>
                      <Link
                        onClick={closeReportModel}
                        to="/reports/shift-report"
                        className="dropdown-item dropdown-toggle arrow-none"
                      >
                        {props.t("Shift Reports")}
                      </Link>
                    </div>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
      {/* overlay  */}
      {props.leftMenu &&
        <div className={`rightbar-overlay z-1 ${classes.leftMenu_overlay}`} onClick={closeLeftmenu} />
      }
      {component &&
        <div className="rightbar-overlay z-1" onClick={closeReportModel} />
      }
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {
    toggleLeftmenu,
  })(withTranslation()(Navbar))
);
