import { post as postAuth, get as getAuth, postImg } from "./axios_auth_api"
import { post as postNonAuth, get as getNonAuth } from "./axios_api"

//////// Buffalo Login ////////////
export const postLogin = async data => {
  try {
    return await postNonAuth("Login", data).then(response => response)
  } catch ({ response }) {
    return response
  }
}

export const getItems = async () =>
  await getAuth("TblItem/getPage?pageSize=1000")

export const postItem = async data => {
  const res = await postAuth("Adminweb/SaveUpdateProductWithIngerdiants", data)
  return res
}

export const getItemNotes = async itemId => {
  const res = await getAuth(`TblItemNote/getPage?userQuery=fkItemId=${itemId}`)
  return res
}

export const getItemIngrediant = async itemId => {
  const res = await getAuth(
    `Adminweb/GetProductIngrediants?userQuery=fk_itemId=${itemId}`
  )
  return res
}

// Tabel Safe
export const getTabelSafe = async () =>
  await getAuth("TblSafe/getPage?userQuery=safe_type=company")

// Expense
export const addExpense = async expense =>
  await postAuth("Adminweb/Expenses", expense)

export const uploadExpenseImage = async (expenseId, file) =>
  await postAuth(
    `FileUpload/UploadExpensesImages?Expense_ID=${expenseId}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  )

// End Shift
export const getShiftEmployees = async () =>
  await getAuth("Adminweb/GetEmpsforEndShift")
export const getShiftDetails = async FK_Shift_ID =>
  await getAuth(
    `Adminweb/GetOpenShiftDetails?userquery=FK_Shift_ID=${FK_Shift_ID}`
  )
export const getManagerApproval = async credentials =>
  await postAuth(`Adminweb/ManagerApproval`, credentials)

export const setEndShiftEmpId = async body => {
  const res = await postAuth("Adminweb/EndShift", body)
  return res
}

export const getEmails = async () => await getAuth("Email/GetEmailsTo")
export const sendEmail = async body =>
  await postAuth(`Email/SendEmail`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  })

// Suppliers
export const getTblSuppliers = async () =>
  await getAuth("TblSupplier/getPage?pageNo=0&pageSize=1000")
export const getTblSupplierPayment = async () =>
  await getAuth("TblSupplierPayment/getPage?pageNo=0&pageSize=1000")
// export const getTblSupplierPayment = async () => await getAuth('TblSupplierPayment/getPage?pageNo=0&pageSize=1000&userQuery=dateFrom=2022-01-01&dateTo=2023-12-31');

export const getMaterials = async () => {
  const res = await getAuth(`TblMaterial/getPage?pageSize=10000`)
  return res
}

export const postMaterial = async data => {
  const response = await postAuth("TblMaterial/save", data)
  return response
}

export const getOneMaterial = async id => {
  return await getAuth(
    `AdminWeb/GetMaterialData?userQuery=PK_Material_ID=${id}`
  )
}

export const getMaterialComponents = async () => {
  const res = await getAuth(`TblMaterialComponent/getPage?pageSize=10000`)
  return res
}

export const postComponent = async data => {
  const res = await postAuth(
    `Adminweb/SaveComponentAndComponentComposition`,
    data
  )
  return res
}

export const getPrepareAreas = async () => {
  const res = await getAuth(`TblPreparArea/getPage?pageSize=1000`)
  return res
}

export const postPrepareArea = async data => {
  const response = await postAuth("TblPreparArea/save", data)
  return response
}

export const getCategories = async () => {
  const res = await getAuth("TblItemCategory/getPage?pageSize=1000")
  return res
}

export const postCategory = async data => {
  const response = await postAuth("TblItemCategory/save", data)
  return response
}
export const postItemImage = async data => {
  const res = await postImg(
    `FileUpload/UploadItemImages?Item_ID=${data.itemId}`,
    data.img
  )
  return res
}

export const postItemNotes = async data => {
  const response = await postAuth("Adminweb/SaveUpdateItemNotes", data)
  return response
}

export const getUnits = async () => {
  const response = await getAuth("TblUnit/getPage")
  return response
}
export const postUnit = async data => {
  const response = await postAuth("TblUnit/save", data)
  return response
}
// Reports
// -- Utils
export const getTblStore = async () =>
  await getAuth(`TblStore/getPage?pageNo=0&pageSize=10000`)
export const getTblMaterial = async () =>
  await getAuth(`TblMaterial/getPage?pageNo=0&pageSize=10000`)
export const getTblEmp = async parameters =>
  await getAuth(
    `TblEmp/getPage?pageNo=0&pageSize=10000&userQuery=${parameters || ""}`
  )
// -- Main
export const getNetProfit = async parameters =>
  await getAuth(`TrainingReports/ProfitInDuration${parameters || ""}`)
export const getDayTrans = async parameters =>
  await getAuth(`TrainingReports/DayTrans${parameters || ""}`)
export const getMonthTrans = async parameters =>
  await getAuth(`TrainingReports/MonthTrans${parameters || ""}`)
export const getSafeBalance = async () =>
  await getAuth(`AdminWeb/SafeBalanceReport`)
export const getStockBalanceFilteredByStock = async parameters =>
  await getAuth(`AdminWeb/GetStockBalancebyStore?userQuery=${parameters || ""}`)
export const getStockBalanceFilteredByMaterials = async parameters =>
  await getAuth(`AdminWeb/GetStockBalancebyItem?userQuery=${parameters || ""}`)
export const getSupplierPayments = async parameters =>
  await getAuth(`AdminWeb/GetSupplierPayments${parameters || ""}`)
export const getExpenseReport = async parameters =>
  await getAuth(`TrainingReports/PaymentsReport${parameters || ""}`)
export const getItemsSales = async parameters =>
  await getAuth(`TrainingReports/ItemReport${parameters || ""}`)
export const getSupplierAccount = async parameters =>
  await getAuth(`TrainingReports/SupplierAccount${parameters || ""}`)
export const getShiftReport = async parameters =>
  await getAuth(`TrainingReports/ShiftAllDetailsReport${parameters || ""}`)

// end shift report
export const getShiftInPreiod = async parameters =>
  await getAuth(`TrainingAdmin/GetShiftsInPeriod${parameters || ""}`)
export const getShiftDetailsByShiftId = async FK_Shift_ID =>
  await getAuth(
    `AdminWeb/GetOpenShiftDetails?userquery=FK_Shift_ID=${FK_Shift_ID}`
  )

// Safe Transfer
export const getSafeList = async () =>
  await getAuth(`TblSafe/getPage?pageSize=10000`)
export const addSafeTransfer = async body =>
  await postAuth(`TblSafeTransfere/save`, body)
export const uploadSafeTransferImage = async (safeTransferId, file) =>
  await postAuth(
    `FileUpload/SafeTransferAttachment?Safetransfer_ID=${safeTransferId}`,
    file,
    { headers: { "Content-Type": "multipart/form-data" } }
  )

// Purchase
export const saveBuyMaterial = async body =>
  await postAuth(`AdminWeb/SaveBuyMaterial`, body)
export const addNewSupplier = async body =>
  await postAuth(`TblSupplier/save`, body)
