/* Material */
export const GET_MATERIALS = "GET_MATERIALS";
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS";
export const GET_MATERIALS_FAIL = "GET_MATERIALS_FAIL";

export const POST_MATERIAL = "POST_MATERIAL";
export const POST_MATERIAL_SUCCESS = "POST_MATERIAL_SUCCESS";
export const POST_MATERIAL_FAIL = "POST_MATERIAL_FAIL";

export const RESET_POST_MATERIAL = "RESET_ADD_MATERIAL";

export const GET_ONE_MATERIAL = "GET_ONE_MATERIAL";
export const GET_ONE_MATERIAL_SUCCESS = "GET_ONE_MATERIAL_SUCCESS";
export const GET_ONE_MATERIAL_FAIL = "GET_ONE_MATERIAL_FAIL";