import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  UncontrolledTooltip,
  Button,
  Row,
  Input,
  Label,
  Spinner,
} from "reactstrap"
import TableContainer from "components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { convertParametersToString } from "../helpers"

import { useSelector, useDispatch } from "react-redux"
import { getShiftReport } from "store/actions"

const FilterByShift = props => {
  const dispatch = useDispatch()
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      shiftID: "",
    },
    validationSchema: Yup.object({
      shiftID: Yup.number(),
    }),
    onSubmit: values => {
      //
    },
  })

  const reports = useSelector(state => state.Reports)
  const { getShiftReport: shiftReportData, loadingShiftReport: loading } =
    reports

  useEffect(() => {
    if (!validation.values.shiftID) return
    let params = {}
    if (validation.values.shiftID) {
      params.shiftID = validation.values.shiftID
    }
    const parameters = convertParametersToString(params)
    dispatch(getShiftReport(parameters))
  }, [validation.values.shiftID])

  const columns = useMemo(
    () => [
      {
        Header: props.t("No"),
        accessor: "PK_OrderMaster_ID",
        filterable: true,
      },
      {
        Header: props.t("Order Type"),
        accessor: "OrderType",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Total"),
        accessor: "TotalPrice",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("After Discount"),
        accessor: "totalafterDiscount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
      {
        Header: props.t("View"),
        Cell: cellProps => {
          const { PK_OrderMaster_ID } = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() =>
                    props.toggleViewModalHandler(PK_OrderMaster_ID)
                  }
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    {props.t("View")}
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    [shiftReportData, props.t]
  )

  return (
    <>
      <Card>
        <CardBody className="border-bottom ml-2">
          <Row className="row-cols-auto gap-4 align-items-center">
            <Label htmlFor="shiftID" className="col-auto col-form-label px-0">
              {props.t("Shift Id")}
            </Label>
            <div className="col-auto">
              <Input
                id="shiftID"
                name="shiftID"
                type="number"
                className="form-control"
                placeholder={props.t("Enter Shift Id")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.shiftID || ""}
                invalid={
                  validation.touched.shiftID && validation.errors.shiftID
                    ? true
                    : false
                }
              />
            </div>
          </Row>
        </CardBody>
        <CardBody>
          {loading ? (
            <div className="text-center py-5">
              <Spinner className="ms-2" color="primary" />
            </div>
          ) : shiftReportData && shiftReportData.Table1 ? (
            <TableContainer
              columns={columns}
              data={
                shiftReportData && shiftReportData.Table1
                  ? shiftReportData.Table1
                  : []
              }
              isGlobalFilter={false}
              customPageSize={5}
              allListSize={
                shiftReportData && shiftReportData.Table1
                  ? shiftReportData.Table1.length
                  : 0
              }
            />
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </>
  )
}

FilterByShift.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilterByShift))
