import React, { useMemo, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import TableContainer from "../../components/Common/TableContainer"

import { Col, Row, Card, CardBody, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { getSafeBalance } from "store/actions"
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf"
import Breadcrumbs from "components/Common/Breadcrumb"

const SafeBalance = props => {
  //meta title
  document.title = "Safe Balance | Reports"
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSafeBalance())
  }, [])

  const reports = useSelector(state => state.Reports)
  const { getSafeBalance: getSafeBalanceData, loadingSafeBalance: loading } =
    reports

  const columns = useMemo(
    () => [
      {
        Header: props.t("Name"),
        accessor: "SafeName",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: props.t("Balance"),
        accessor: "Balance",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || 0}</>
        },
      },
    ],
    [props.t]
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Safe Balance")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {loading ? (
                    <div className="text-center py-5">
                      <Spinner className="ms-2" color="primary" />
                    </div>
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={getSafeBalanceData ? getSafeBalanceData : []}
                      isAddOptions={false}
                      customPageSize={5}
                      allListSize={
                        getSafeBalanceData ? getSafeBalanceData.length : []
                      }
                    />
                  )}
                </CardBody>
                {getSafeBalanceData &&
                  (getSafeBalanceData.length && !loading ? (
                    <div className="mt-2 d-flex justify-content-end">
                      <SendAndPrintPdf
                        data={getSafeBalanceData}
                        extraData={null}
                        filteredData={["No", "SafeName", "Balance"]}
                        extraTwoColData={null}
                      />
                    </div>
                  ) : (
                    <></>
                  ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

SafeBalance.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SafeBalance))
