import React, { useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import TableContainer from "components/Common/TableContainer";
import { Category, Name, PrepareArea, Price, SecondName } from "pages/Products/contactlistCol";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

const ProductList = (props) => {
    const { item, handleCreateNewItem, handleEditItem } = props;

    const columns = useMemo(
        () => [
            // {
            //   Header: "#",
            //   Cell: () => {
            //     return <input type="checkbox" className="form-check-input" />;
            //   },
            // },

            // {
            //     Header: props.t("Img"),
            //     // accessor: "itemImage",
            //     disableFilters: true,
            //     filterable: true,
            //     accessor: cellProps => (
            //         <>
            //             {!cellProps.itemImage || cellProps.itemImage === "NULL" ? (
            //                 <div className="avatar-xs">
            //                     <span className="avatar-title rounded-circle"></span>
            //                 </div>
            //             ) : (
            //                 <div>
            //                     {/* To handle binary data in a database, you can use the `base64` method. */}
            //                     {/* src={`data:image/jpeg;base64,${cellProps.itemImage2}`} */}

            //                     <img
            //                         className="rounded-circle avatar-xs"
            //                         src={cellProps.itemImage}
            //                         alt=""
            //                     />
            //                 </div>
            //             )}
            //         </>
            //     ),
            // },
            {
                Header: props.t("Item Name “1st Lang”"),
                accessor: "itemName",
                filterable: true,
                Cell: cellProps => {
                    return <Name {...cellProps} />;
                },
            },
            {
                Header: props.t("Item Name “2nd Lang”"),
                accessor: "itemNameLang2",
                filterable: true,
                Cell: cellProps => {
                    return <SecondName {...cellProps} />;
                },
            },
            {
                Header: props.t("Prepare Area"),
                accessor: "prepareAreaName",
                filterable: true,
                Cell: cellProps => {
                    return <PrepareArea {...cellProps} />;
                },
            },
            {
                Header: props.t("Category"),
                accessor: "mainCategoryName",
                filterable: true,
                Cell: cellProps => {
                    return <Category {...cellProps} />;
                },
            },
            {
                Header: props.t("Price"),
                accessor: "price",
                filterable: true,
                Cell: cellProps => {
                    return (
                        <>
                            {" "}
                            <Price {...cellProps} />{" "}
                        </>
                    );
                },
            },
            {
                Header: props.t("Action"),
                Cell: cellProps => {
                    return (
                        <div className="d-flex gap-3">
                            <Link
                                to="#"
                                className="text-success"
                                onClick={() => {
                                    const userData = cellProps.row.original;
                                    handleEditItem(userData);
                                }}
                            >
                                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    {props.t("Edit")}
                                </UncontrolledTooltip>
                            </Link>
                            {/* <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original
                  onClickDelete(userData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link> */}
                        </div>
                    );
                },
            },
        ],
        [props.t]
    );
    return <>
        <TableContainer
            columns={columns}
            data={item}
            isGlobalFilter={true}
            isAddUserList={true}
            handleUserClick={handleCreateNewItem}
            customPageSize={5}
            allListSize={item.length}
            newItemBtnTxt={props.t("Create New Product")}
            className="custom-header-css"
        />
    </>;
};

ProductList.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ProductList));