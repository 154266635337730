import {
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPLOAD_EXPENSE_IMAGES,
    UPLOAD_EXPENSE_IMAGES_SUCCESS,
    UPLOAD_EXPENSE_IMAGES_FAIL,
} from "store/expense/actionTypes";

const addExpense = expense => ({
    type: ADD_EXPENSE,
    payload: expense
});

const addExpenseSuccess = expense => ({
    type: ADD_EXPENSE_SUCCESS,
    payload: expense
});

const addExpenseFail = error => ({
    type: ADD_EXPENSE_FAIL,
    payload: error
});

const uploadExpenseImage = expense => ({
    type: UPLOAD_EXPENSE_IMAGES,
    payload: expense
});

const uploadExpenseImageSuccess = expense => ({
    type: UPLOAD_EXPENSE_IMAGES_SUCCESS,
    payload: expense
});

const uploadExpenseImageFail = error => ({
    type: UPLOAD_EXPENSE_IMAGES_FAIL,
    payload: error
});

export {
    addExpense,
    addExpenseSuccess,
    addExpenseFail,
    uploadExpenseImage,
    uploadExpenseImageSuccess,
    uploadExpenseImageFail,
};