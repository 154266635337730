import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import { Button, CardBody, Spinner } from "reactstrap"
import TableContainer from "../../../components/Common/TableContainer"
import Select from "react-select"
import { Type, Value, Amount } from "./StockBalanceCol"
import { useSelector, useDispatch } from "react-redux"
import { getStockBalanceFilteredByStock, getOneMaterial } from "store/actions"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"
import MaterialDetails from "./Modal/MaterialDetails"
import ComponentModal from "./Modal/ComponentModal"

const FilteredByStock = props => {
  const dispatch = useDispatch()
  const [storeData, setStoreData] = useState([])
  const [selectedStore, setSelectedStore] = useState({})
  const [currentStoreId, setcurrentStoreId] = useState("")

  const [showMaterialModal, setShowMaterialModal] = useState(false)
  const [showComponentModal, setShowComponentModal] = useState(false)
  const [materialAllDetails, setMaterialAllDetails] = useState("")
  const [componentAllDetails, setComponentAllDetails] = useState("")

  const layout = useSelector(state => state.Layout)
  const reports = useSelector(state => state.Reports)
  const oneMaterialDetails = useSelector(state => state.materials.oneMaterial)

  const { layoutModeType } = layout
  const {
    getTblStore: getTblStoreData,
    getStockBalanceFilteredByStock: columnData,
    loadingStockBalanceFilteredByStock: loading,
  } = reports

  useEffect(() => {
    const newStoreData = getTblStoreData.map(one => ({
      value: one.storeName,
      label: one.storeName,
      pkStoreId: one.pkStoreId,
    }))
    newStoreData.unshift({
      value: "Selcet",
      label: "Selcet",
      pkStoreId: "Selcet",
    })
    setSelectedStore(newStoreData[0])
    setStoreData(newStoreData)
  }, [getTblStoreData])

  useEffect(() => {
    if (currentStoreId && currentStoreId !== "Selcet") {
      dispatch(getStockBalanceFilteredByStock(`fk_store_id=${currentStoreId}`))
    }
  }, [currentStoreId])

  const toggleMaterialModal = () => {
    setShowMaterialModal(prevState => !prevState)
  }
  const toggleComponentModal = () => {
    setShowComponentModal(prevState => !prevState)
  }

  const handleMaterialData = data => {
    //toggleMaterialModal();
    // data.MatType === "Raw" ? toggleMaterialModal() : toggleComponentModal()
    dispatch(getOneMaterial(data.PK_Material_ID))
    data.MatType === "Raw" && toggleMaterialModal()
    data.MatType === "Raw"
      ? setMaterialAllDetails(data)
      : setComponentAllDetails(data)
  }

  useEffect(() => {
    setMaterialAllDetails(oneMaterialDetails)
  }, [oneMaterialDetails])
  const columns = useMemo(
    () => [
      {
        Header: props.t("Material Name"),
        accessor: "MaterialName",
        filterable: true,
        Cell: cellProps => {
          // return <Value {...cellProps} />;
          return (
            <>
              <Button
                className={` ${
                  cellProps.row.original.MatType === "Raw"
                    ? "btn-light btn"
                    : "btn-disabled disabled"
                } `}
                onClick={() => {
                  handleMaterialData(cellProps.row.original)
                }}
              >
                {cellProps.value}
              </Button>
            </>
          )
        },
      },
      {
        Header: props.t("Type"),
        accessor: "MatType",
        filterable: true,
        Cell: cellProps => {
          // return <Type {...cellProps} />;
          return <>{cellProps.value === "Raw" ? "Material" : "Component"}</>
        },
      },
      {
        Header: props.t("Unit"),
        accessor: "Unit",
        filterable: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: props.t("Balance"),
        accessor:
          columnData &&
          (columnData.length > 0 && columnData[0].TotalQty
            ? "TotalQty"
            : "Qty"),
        filterable: true,
        Cell: cellProps => {
          return <Amount {...cellProps} />
        },
      },
    ],
    [columnData, props.t]
  )

  return (
    <>
      <CardBody className="border-bottom ml-2">
        <div className="form-group row px-0 align-items-center">
          <label className="col-auto col-form-label px-0">
            {props.t("Store Name")}
          </label>
          <div className="col-auto" style={{ width: "220px" }}>
            <Select
              value={selectedStore}
              onChange={selectedOption => {
                setSelectedStore(selectedOption)
                setcurrentStoreId(selectedOption.pkStoreId)
              }}
              options={storeData}
              className="select2-selection form-control p-0 border-0"
              styles={{
                menu: base => ({
                  ...base,
                  backgroundColor:
                    layoutModeType === layoutModeTypes.LIGHT
                      ? selectInputBgColors.light
                      : selectInputBgColors.dark,
                }),
              }}
            />
          </div>
        </div>
      </CardBody>
      {loading ? (
        <div className="text-center py-5">
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : columnData.length ? (
        <TableContainer
          columns={columns}
          data={columnData ? columnData : []}
          customPageSize={5}
          allListSize={columnData ? columnData.length : 0}
        />
      ) : (
        <></>
      )}
      <MaterialDetails
        materialModal={showMaterialModal}
        handleMaterialToggle={toggleMaterialModal}
        materialData={materialAllDetails}
      />
      <ComponentModal
        componentModal={showComponentModal}
        handleComponentToggle={toggleComponentModal}
        componentData={componentAllDetails}
      />
    </>
  )
}

FilteredByStock.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(FilteredByStock))
