import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
// import UnitModal from "./UnitModal";
import { toast } from "react-toastify"
// import { postMaterial } from "../../../store/products/materials/actions";
import {
  getUnits,
  resetPostUnit,
  resetModelType,
  postMaterial,
} from "store/actions"

const MaterialDetails = props => {
  const dispatch = useDispatch()
  const { handleMaterialToggle, materialModal } = props

  const materialErr = useSelector(state => state.materials.error)
  const units = useSelector(state => state.units.allUnits)

  const materials = useSelector(state => state.materials)
  const { oneMaterial } = materials

  const [materialName, setMaterialName] = useState("")
  const [selectedUnit, setSelectedUnit] = useState("")
  const [minOrder, setMinOrder] = useState(0)
  //   const [unitModal, setUnitModal] = useState(false);
  const [unit, setUnit] = useState([])
  const [unitUpdated, setUnitUpdated] = useState(false)

  const [materialAddErr, setMaterialAddErr] = useState(null)

  const [isEditing, setIsEditing] = useState(false)

  const toggle = () => {
    handleMaterialToggle()
  }

  // useEffect(() => {
  //   resetField()
  //   if (materialData) {
  //     setMaterialName(materialData.MaterialName)
  //   }
  // }, [materialData, materialModal])

  // display data
  useEffect(() => {
    resetField()
    if (oneMaterial.length) {
      setMaterialName(oneMaterial[0].MaterialName ? oneMaterial[0].MaterialName : "")
      setSelectedUnit(oneMaterial[0].Unit ? oneMaterial[0].Unit : "")
      setMinOrder(oneMaterial[0].Mindemand ? oneMaterial[0].Mindemand : 0)
    }
  }, [oneMaterial, materialModal])

  useEffect(() => {
    if (units && !units.length) {
      dispatch(getUnits())
    }
  }, [dispatch, units])

  useEffect(() => {
    // fetchUnits();
    setUnit(units)
  }, [unitUpdated, units])

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      materialName: materialName || "",
      selectedUnit: selectedUnit || "",
      minOrder: minOrder || "",
    },
    validationSchema: Yup.object({
      materialName: Yup.string().required(
        props.t("Please Enter Material Name")
      ),
      selectedUnit: Yup.string().required(props.t("Please select unit")),
      minOrder: Yup.number().required(props.t("Please enter min. order")),
    }),
    onSubmit: async values => {
      const newMaterial = {
        materialName: values["materialName"],
        fkUnitId: Number(values["selectedUnit"]),
        mindemand: Number(values["minOrder"]),
        material: false,
        matPrice: null,
      }

      try {
        dispatch(postMaterial(newMaterial))
        if (Object.keys(materialErr).length !== 0) {
        } else {
          validation.resetForm()
          toggle()
        }
      } catch (err) {
        console.log(err)
      }
    },
  })


  const handleCancel = () => {
    toggle()
  }
  const handleSelectUnit = e => {
    setSelectedUnit(e.target.value)
  }

  function resetField() {
    setSelectedUnit("")
    setIsEditing(false)
    setMaterialName("")
    setMinOrder(0)
  }

  return (
    <Modal isOpen={materialModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Show Material")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col xs={12}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Raw Material")}</Label>
                <Input
                  name="materialName"
                  type="text"
                  placeholder={props.t("Enter Raw Material")}
                  onChange={e => setMaterialName(e.target.value)}
                  onBlur={validation.handleBlur}
                  value={validation.values.materialName || ""}
                  invalid={
                    validation.touched.materialName &&
                    validation.errors.materialName
                      ? true
                      : false
                  }
                  disabled={isEditing ? false : true}
                />
                {validation.touched.materialName &&
                validation.errors.materialName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.materialName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <label className="form-label">{props.t("Default Unit")}</label>
              <div className="input-group">
                <Col xs={12}>
                  <Input
                    name="selectedUnit"
                    type="text"
                    placeholder={props.t("Enter Raw Material")}
                    onChange={e => setSelectedUnit(e.target.value)}
                    onBlur={validation.handleBlur}
                    value={validation.values.selectedUnit || ""}
                    invalid={
                      validation.touched.selectedUnit &&
                      validation.errors.selectedUnit
                        ? true
                        : false
                    }
                    disabled={isEditing ? false : true}
                  />
                  {validation.touched.selectedUnit &&
                  validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null}
                  {/* <Input
                    name="selectedUnit"
                    type="select"
                    onChange={e => {
                      handleSelectUnit(e)
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values.selectedUnit || ""}
                    invalid={
                      validation.touched.selectedUnit &&
                      validation.errors.selectedUnit
                        ? true
                        : false
                    }
                    disabled={isEditing ? false : true}
                  >
                    <option value="">{props.t("Select")}</option>
                    {unit.map((unit, index) => (
                      <option key={index} value={unit.pkUnitId}>
                        {unit.unit}
                      </option>
                    ))}
                  </Input>
                  {validation.touched.selectedUnit &&
                  validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null} */}
                </Col>
                {/* <Col xs={2}>
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleUnitModal}
                  >
                    {props.t("Add")}
                  </button>
                </Col> */}

                {validation.touched.unit && validation.errors.unit ? (
                  <FormFeedback type="invalid">
                    {validation.errors.unit}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Minimum Order")}</Label>
                <div className="input-group">
                  <Input
                    name="minOrder"
                    type="number"
                    placeholder={props.t("Enter Minimum Order")}
                    onChange={e => setMinOrder(e.target.value)}
                    onBlur={validation.handleBlur}
                    value={validation.values.minOrder || ""}
                    invalid={
                      validation.touched.minOrder && validation.errors.minOrder
                        ? true
                        : false
                    }
                    min={0}
                    disabled={isEditing ? false : true}
                  />
                  {validation.touched.minOrder && validation.errors.minOrder ? (
                    <FormFeedback type="invalid">
                      {validation.errors.minOrder}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  <i className="mdi mdi-check-circle-outline me-1" />
                  {props.t("Save")}
                </button> */}
          {/* <Button onClick={() => setIsEditing(true)} className="btn btn-warning mx-1">
                    <i className="mdi mdi-pencil me-1" />
                    {props.t("Edit")}
                </Button> */}
          {/* <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row> */}
        </Form>
        {/* <UnitModal
          unitModal={unitModal}
          handleUnitToggle={handleUnitToggle}
          handleUnitUpdated={handleUnitUpdated}
        /> */}
      </ModalBody>
    </Modal>
  )
}

MaterialDetails.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(MaterialDetails))
